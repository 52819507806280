import { ANALYTIC_FEATURES } from 'constants/app.constant';

export const psRoutePrefix = {
  instructor: '/programSolution'
};

export const PS_PATHS = {
  homePage: `${psRoutePrefix.instructor}`,
  courseSummaryPage: `${psRoutePrefix.instructor}/courseSummary`,
  studentProfile: `${psRoutePrefix.instructor}/studentProfile/:evolveUsername`
} as const;

export const PS_STUDENT_PROFILE_TABS = {
  hesiExams: {
    hash: '#hesiExams',
    name: 'HESI Exams',
    index: 0,
    trackAAEventName: ANALYTIC_FEATURES.PS_STUDENT_PROFILE_HESI_EXAMS_TAB_CLICK
  },
  sherpathAssignments: {
    hash: '#sherpathAssignments',
    name: 'Sherpath Assignments',
    index: 1,
    trackAAEventName: ANALYTIC_FEATURES.PS_STUDENT_PROFILE_SHERPATH_ASSIGNMENTS_TAB_CLICK
  },
  shadowHealthAssignments: {
    hash: '#shadowHealthAssignments',
    name: 'Shadow Health Assignments',
    index: 2,
    trackAAEventName: ANALYTIC_FEATURES.PS_STUDENT_PROFILE_SHADOW_HEALTH_ASSIGNMENTS_TAB_CLICK
  },
  categoryPerformance: {
    hash: '#categoryPerformance',
    name: 'Category Performance',
    index: 3,
    trackAAEventName: ANALYTIC_FEATURES.PS_STUDENT_PROFILE_CATEGORY_PERFORMANCE_TAB_CLICK
  },
  ngnDetails: {
    hash: '#ngnDetails',
    name: 'NGN Details',
    index: 4,
    trackAAEventName: ANALYTIC_FEATURES.PS_STUDENT_PROFILE_NGN_DETAILS_TAB_CLICK
  }
} as const;

export const PS_STUDENT_PROFILE_TABS_MODAL = Object.values(PS_STUDENT_PROFILE_TABS);

export const TOPIC_AREA_TABS_DATA = {
  chapterMastery: { name: 'EAQ Topic Mastery', id: 'chapter-mastery' },
  hesiExamCategories: { name: 'HESI Exam Categories', id: 'hesi-exam-categories' },
  shadowHealthCategories: { name: 'Shadow Health Categories (Coming Soon)', id: 'shadow-health-categories' }
} as const;

export const HESI_SCORE_TYPE = {
  likelyScore: 'likely-score',
  meanScore: 'mean-score'
} as const;

export const hesiScoreOptions = [
  {
    key: HESI_SCORE_TYPE.likelyScore,
    name: 'Likely HESI Exit Score'
  },
  {
    key: HESI_SCORE_TYPE.meanScore,
    name: 'Mean HESI Exit Score'
  }
];

export const HESI_EXAMS_TABS_DATA = {
  examPerformance: { name: 'Exam Results', id: 'exam-performance' },
  hesiExitPredictions: { name: 'Monthly Trend', id: 'hesi-exit-predictions' }
} as const;

export const RESOURCE_TYPES = {
  ADAPTIVE_LESSON: {
    id: 'ADAPTIVE_LESSON',
    displayName: 'Lesson'
  },
  SHERPATH_LESSON: {
    id: 'SHERPATH_LESSON',
    displayName: 'Lesson'
  },
  SHERPATH_SIMULATION: {
    id: 'SHERPATH_SIMULATION',
    displayName: 'Simulation'
  },
  SHERPATH_SKILL: {
    id: 'SHERPATH_SKILL',
    displayName: 'Skill'
  },
  SHADOW_HEALTH: {
    id: 'SHADOW_HEALTH',
    displayName: 'ShadowHealth'
  },
  OSMOSIS_VIDEO: {
    id: 'OSMOSIS_VIDEO',
    displayName: 'Osmosis'
  },
  REVIEW_QUIZ: {
    id: 'REVIEW_QUIZ',
    displayName: 'Interactive Review Questions'
  }
};

export const UNSUPPORTED_RESOURCE_TYPE = ['LMS', 'DOWNLOAD_BY_RESOURCE_TYPE', 'DOWNLOADS'];

export const MAX_RESOURCE_MASTERY_ITEMS_PER_PAGE = 5;

export const PS_COURSE_SUMMARY_TABS = {
  sherpath: {
    hash: '#sherpath',
    index: 0
  },
  shadowHealth: {
    hash: '#shadowHealth',
    index: 1
  },
  hesi: {
    hash: '#hesi',
    index: 2
  }
} as const;

export const USE_FETCH_KEYS = {
  ACTIVE_CWCOURSES_COUNT: 'ACTIVE_CWCOURSES_COUNT',
  ACTIVE_SHCOURSES_COUNT: 'ACTIVE_SHCOURSES_COUNT',
  PROGRAM_MEAN_HESI_SCORE: 'PROGRAM_MEAN_HESI_SCORE',
  HESI_PROGRAMS: 'HESI_PROGRAMS',
  FETCH_PROGRAMS: 'FETCH_PROGRAMS',
  FETCH_PROGRAMS_ASSESS_REVIEW: 'FETCH_PROGRAMS_ASSESS_REVIEW',
  FETCH_COHORTS: 'FETCH_COHORTS',
  FETCH_SH_USER: 'FETCH_SH_USER'
} as const;

export const COHORT_STALE_TIME = 1000 * 60;

export const STUDENT_THRESHOLD_CATEGORY_TABLE_KEY = 'studentThresholdCategoryTable';
export const STUDENT_THRESHOLD_EXAM_SCORE_KEY = 'studentThresholdExamScore';
export const PS_DASHBOARD_THRESHOLD_EXAM_SCORE_KEY = 'psDashboardThresholdExamScore';
